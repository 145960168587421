import React from 'react'
import { Helmet } from 'react-helmet'
import './scss/all.scss'
import useSiteMetadata from './SiteMetadata'
import { withPrefix } from 'gatsby'
import '../fonts/font_awesome/font-awesome'

const TemplateWrapper = ({ children, location }) => {
  const { siteName, title, siteURL } = useSiteMetadata()
  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <link rel="canonical" href={`${siteURL}${location.pathname}`} />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix('/')}img/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-48x48.png`}
          sizes="48x48"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-16x16.png`}
          sizes="16x16"
        />

        <meta name="theme-color" content="#fff" />

        <meta property="og:site_name" content={siteName} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`${siteURL}${location.pathname}`} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@NatObserver" />
        <meta name="twitter:site:id" content="592110789" />
        <meta name="twitter:url" content={`${siteURL}${location.pathname}`} />
      </Helmet>

      {children}

    </div>
  )
}

export default TemplateWrapper
