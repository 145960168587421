/**
 * @file Font Awesome SVG JS Core
 * We use fontawesome-svg-core in order to only load icons that we use.
 * @see https://fontawesome.com/how-to-use/with-the-api/setup/library#subsetting
 * For package names:
 * @see https://github.com/FortAwesome/Font-Awesome/blob/master/UPGRADING.md#50x-to-510
 */
import { library, dom } from '@fortawesome/fontawesome-svg-core'

// Regular style
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons'
// Light style
import { faArrowDown } from '@fortawesome/pro-light-svg-icons'
// Solid style
import { faMobileAndroidAlt } from '@fortawesome/pro-solid-svg-icons'
import { faUsers, faPodcast } from '@fortawesome/pro-solid-svg-icons'
// Brand icons
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faFacebookF } from '@fortawesome/free-brands-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faCanadianMapleLeaf } from '@fortawesome/free-brands-svg-icons'

library.add(
  // Regular style
  faChevronLeft,
  // Light style
  faArrowDown,
  // Solid style
  faMobileAndroidAlt,
  faUsers,
  faPodcast,
  // Brand icons
  faTwitter,
  faFacebookF,
  faLinkedin,
  faCanadianMapleLeaf
);

// Replace any existing <i> tags with <svg> and set up a MutationObserver to
// continue doing this as the DOM changes.
dom.watch();
